import model from '../mixins/model';

export default {
    mixins: [model],
    form: true,
    model() {
        return {
            invoice: []
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        submit(e) {
            e.preventDefault();

            return this.dispatch((resolve,reject) => {
                this.$http.request({ url: 'earnings/invoice', data: this.model.invoice, method: 'POST'})
                    .then((response) => {
                        if (!response.data.data.seller) {
                            this.$router.push({name:'agent.invoice'});
                        } else {
                            this.$router.push(this.normalizePath({path: '/podglad-faktury/' + response.data.invoiceUid})).then(resolve);
                        }
                    })
                    .catch(reject)
            });
        },
        fetch() {
            this.dispatch((resolve, reject) => {
                this.$http.request({ url: 'earnings/invoice' })
                    .then((response) => {
                        this.model.invoice = response.data.data;
                    })
                    .then(resolve)
                    .catch(reject);
            });
        },
    }
}