import model from '../mixins/model';

export default {
    mixins: [model],
    model() {
        return {
            earnings: [],
            month: '',
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.dispatch((resolve, reject) => {
                this.$http.request({ url: 'earnings/index' })
                    .then((response) => {
                        this.model.earnings = response.data.earnings
                        this.model.month = response.data.month
                    })
                    .then(resolve)
                    .catch(reject);
            });
        },
    }
}