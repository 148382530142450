import model from '../mixins/model';

export default {
    mixins: [model],
    form: true,
    model() {
        return {
            invoice: []
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.dispatch((resolve, reject) => {
                this.$http.request({ url: 'earnings/invoice/:invoice' })
                    .then((response) => {
                        this.model.invoice = response.data.invoice;
                    })
                    .then(resolve)
                    .catch(reject);
            });
        },
        downloadPDF() {
            this.dispatch((resolve, reject) => {
                this.$http.request({ url: 'earnings/invoicePdf/:invoice', responseType: 'arraybuffer'})
                    .then((response) => {
                        let link = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                        let fileLink = document.createElement('a');

                        fileLink.href = link;
                        fileLink.setAttribute('download', 'Faktura.pdf');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .then(resolve)
                    .catch(reject);
            }, false);
        },
        downloadReport() {
            this.dispatch((resolve, reject) => {
                this.$http.request({url: 'earnings/invoicePdf/:invoice/report', responseType: 'arraybuffer'})
                    .then((response) => {
                        let link = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                        let fileLink = document.createElement('a');

                        fileLink.href = link;
                        fileLink.setAttribute('download', 'Raport-z-faktury.pdf');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .then(resolve)
                    .catch(reject);
            }, false);
        }
    }
}