<template>
<div>
    <router-view  v-if="!$auth.isLoading()"/>
    <div v-else>
        <div class="fill d-flex align-items-center justify-content-center max-height d-block" >
            <div class="text-center py-3">
                <div class="logo py-5 text-center">

                    <img alt="Loando Direct" src="./assets/loando-logo-dark.svg"/>
                </div>
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div>
                    Trwa uwierzytelnianie
                </div>
            </div>
        </div>
    </div>
    <Snack :snack="snackMessage" :show="show" />
</div>
</template>
<script>
import Snack from "@/components/Snack.vue";
import {SnackEnum} from "@/enums/snacks/snack.enum";

export default {
  components: {Snack},
    provide() {
        return {
            snack: this.snack,
        }
    },
    methods: {
        snackType(type, message = null, title = null) {
          this.snackMessage = {title: title, message: message, type: type};
          setTimeout(() => this.show = true, 100);
          setTimeout(() => this.show = false, 4000);

          return true;
        }
    },
    data() {
        return {
            show: true,
            snackMessage: null,
            snack: {
              success: this.snackType.bind(this, SnackEnum.success),
              danger: this.snackType.bind(this, SnackEnum.danger),
              info: this.snackType.bind(this, SnackEnum.info)
            }
        }
    },
}
</script>