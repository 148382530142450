import model from '../mixins/model';
import moment from "moment";

export default {
    mixins: [model],
    query() {
        return {
            show: 50,
            page: 1,
            from_at: null,
            withMobileApp: null,
            leadFormFinished: null,
            leadAgreementsSent: null,
            leadAgreementsChecked: null,
            leadProcessingStarted: null,
            leadAccepted: null,
            leadRedirected: null,
            leadPaid: null,
            actionDontCall: null,
            actionNotAnsweredThePhone: null,
            actionPleaseCallBack: null,
            actionNote: null,
            actionDecisionUndertaking: null,
            actionVoiceMail: null,
            'created_at': {start: null, end: null},
            'personId': { equal: null },
            'email': { equal: null },
            'phone': { equal: null },
            'firstName': { equal: null },
            'lastName': { equal: null },
            'status_id': { in: [] }
        }
    },
    watch: {
        'query.from_at': {
            handler() {
                let start = moment().startOf('day');

                switch (this.query.from_at) {
                    case '7days':
                        start =  start.subtract(7, 'days').utc().format();
                        break;
                    case '3days':
                        start = start.subtract(3, 'days').utc().format();
                        break;
                    case 'yesterday':

                        start = start.subtract(1, 'days').utc().format();
                        break;
                    case 'today':
                        start = start.utc().format();
                        break;
                    case 'custom':
                    default:
                        start = null;
                        break;
                }

               this.query.created_at = {start,end:null};

            }
        },
        '$globalQueries.params.agent_id': {
            handler(newValue, oldValue) {

                if(oldValue !== undefined) this.fetch();

            },
            deep: true
        }
    },
    model() {
        return {
            leads: []
        }
    },
    mounted() {

        console.log('bind mounted');

        this.bindQuery();
        this.fetch();

    },
    methods: {
        fetch() {
            this.dispatch((resolve, reject) => {


                console.log('pobieram fetch ', this.query);

                this.$router.push({query: this.query}).then(() => {

                    console.log('skonczylem ustawiac get')


                    this.$http.request({ url: 'leads', params: {...this.query,  agent_id:  this.$globalQueries.params.agent_id }})
                        .then((response) => {
                            this.model.leads = response.data.leads;
                            this.pagination = response.data.pagination;
                            console.log('skonczylem pobierac fetch')
                        })
                        .then(resolve)
                        .catch(reject);
                });



            });


        },
    }
}