<template>
  <div v-if="snack" class="snackbar d-flex align-items-center flex-row" :class="{
    'show': show, [snack.type ?? '']: true
  }">
    <v-icon :icon="getIcon" class="snack-icon" :class="{[snack.type ?? '']:  true}"/>
    <div class="d-flex flex-column justify-content-start align-items-start">
      <span v-if="snack.title"><b>{{ snack.title }}</b></span>
      <span class="snack-message" v-if="snack.message">{{ snack.message }}</span>
    </div>
  </div>
</template>
<script>
import {SnackEnum} from "@/enums/snacks/snack.enum";
export default {
  props: {
    snack: {},
    show: false,
  },
  computed: {
    getIcon() {
      if (this.snack.type === SnackEnum.success) {
        return 'check-line';
      }
      if (this.snack.type === SnackEnum.info) {
        return 'pushpin-line'
      }
      if (this.snack.type === SnackEnum.danger) {
        return 'close-fill';
      }
    }
  }
}
</script>