<template>
  <div class="my-1" v-for="item in items">
    <v-control-toggle v-if="item.id" v-model="value[item.source_id]" :color="['white','light']" :options="[null, item.source_id]"
                      class="btn-sm d-flex align-items-center me-1" style="height: 38px;"
                      :img="isThumbnail ? {'src': item.thumbnail, 'alt': item.name} : null" />
  </div>
</template>
<script>
import control from "@/mixins/control";
import VControlToggle from "@/components/Control/Toggle.vue";

export default {
  name: "v-control-multi-toggle",
  components: {VControlToggle},
  mixins: [control],
  props: {
    items: [],
    isThumbnail: {type: Boolean, default: false},
  }
}
</script>