<template>
    <table class="table align-middle table-hover table-striped">
        <thead>
        <tr>
            <th v-for="(header,index) in headers" :key="index" :class="header.props?.class" v-bind="header.attrs">
                {{ header.props?.label }}
            </th>
        </tr>
        </thead>
        <tbody>
        <v-collection :items="items">
            <template v-slot:default="{ item }">
                <tr>
                    <slot :item="item" name="default"/>
                </tr>
            </template>

            <template v-slot:empty>
                <tr>
                    <td :colspan="headers.length" class="text-center">
                        <p class="text-secondary p-3 mb-0" :class="{'index': Math.random()}"> Brak wyników </p> <!-- @todo Dlaczego brak wyników się dubluje przy dwóch tabelach na stronie? klasa index jest tymczasowa, moze uzywajac h()? -->
                    </td>
                </tr>
            </template>
            <template v-slot:loading>
                <tr>
                    <td :colspan="headers.length" class="text-center bg-white">
                        <v-loading text="Trwa wczytywanie..." color="secondary my-2"></v-loading>
                    </td>
                </tr>
            </template>
        </v-collection>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "v-table",
    props: {
        items: {}
    },
    computed: {
        headers() {
            return this.$slots.default({item: null}).filter(x => x.type?.name === 'v-column');
        }
    }
}
</script>
