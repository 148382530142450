<template>
    <section>
        <v-model.client v-slot="{ model }">

            <div class="d-flex justify-content-between">
                <div>
                    <v-button @click="$router.go(-1)" class="px-0 py-2" color="transparent"
                              icon="arrow-left-s"/>
                </div>
                <div class="hgroup flex-grow-1">
                    <h1 v-if="model.client.name ">{{ model.client.name }}</h1>
                    <h1 v-else>{{ model.client.lastName }} {{ model.client.firstName }}</h1>
                    <h2>Szczegóły klienta</h2>
                </div>
            </div>
            <div class="card">
                <div class="card-body">

                    <span class="card-title">Dane klienta</span>

                    <div class="d-flex justify-content-between label-text align-items-end">
                        <p>
                            <span class="label">Imię</span>
                            <span class="text">{{ model.client.firstName || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">Nazwisko</span>
                            <span class="text">{{ model.client.lastName || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">Telefon</span>
                            <span class="text">{{ model.client.phone || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">Pesel</span>
                            <span class="text">{{ model.client.personId || model.client.pesel || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">Email</span>
                            <span class="text">{{ model.client.email || '-' }}</span>
                        </p>
                        <p v-if="$auth.permissions.mobileEnabled">
                            <span class="label">Aplikacja</span>
                            <span class="text clients">

                                  <span class="d-flex align-items-center" :class="{'text-green': model.client?.hasMobileApp, 'text-red': !model.client?.hasMobileApp}">
                                 <v-icon icon="smartphone"></v-icon>
                                <span class="fw-700 text-small text-uppercase mt-1 mx-1">{{ model.client?.hasMobileApp ? 'Aktywna' : 'Brak' }}</span>
                        </span>


                            </span>
                        </p>
                        <div class="d-grid gap-2">

                            <v-button :outline="true" color="secondary" icon="user" label="Szczegóły klienta" @click="advanced = !advanced"/>
                            <p v-if="$auth.permissions.mobileEnabled &&  model.client?.hasMobileApp">
                            <v-button v-if="model.client.uid" :route="{name:'agent.chats.conversation', params:{client:model.client.uid}}" :outline="true" color="secondary" icon="message-line-1"
                                      label="Rozpocznij rozmowę z klientem"
                                      size="xs"/>

                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div v-if="advanced" class="card">
                <div class="card-body">
                    {{ model.user }}
                    <div class="mb-4" v-if="model.agent?.firstName">
                        <div class="mb-2">
                            <span class="card-title mb-0">Agent</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <img :alt="model?.agent?.firstName+' '+model?.agent?.lastName" :src="model?.agent?.avatar"
                                 class="rounded avatar ms-0"/>
                            <div>
                                <v-agent :agent="model.agent"/>

                            </div>
                        </div>
                    </div>
                    <v-model.client.data v-slot="{ model }">
                        <v-collection :items="model.data">

                            <template v-slot:default="{ item }">
                            <span class="card-title">{{ item.group_name }}</span>
                            <div class="striped mt-3 mb-4">
                                <v-collection v-slot="{item}" :items="item.fields">
                                    <div class="striped-item">
                                        <div class="row">
                                            <div class="col-md p-3">
                                                <strong>{{ item.label }}</strong>
                                            </div>
                                            <div class="col-md p-3">
                                                <span>{{ item.value }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </v-collection>
                            </div>

                            </template>
                            <template v-slot:loading>
                                <div class="py-3">
                                    <v-loading text="Trwa wczytywanie" color="secondary" />
                                </div>
                            </template>
                            <template v-slot:empty>
                                <span>Brak danych klienta</span>
                            </template>

                        </v-collection>
                    </v-model.client.data>
                </div>
            </div>


            <v-model.client.leads v-slot="{model}">

                <div class="card">
                    <div class="card-body">

                        <v-table v-slot="{item}" :items="model.leads" class="clients">
                            <v-column label="Data wniosku">
                                <v-date :date="item.created_at" class="d-block" format="YYYY/MM/DD"/>
                                <v-date :date="item.created_at" class="d-block" format="HH:mm"/>
                            </v-column>
                            <v-column label="Status wniosku" style="width: 180px">
                                <div class="d-flex justify-content-between">
                                    <v-collection v-slot="{item}" :items="item.statuses.event_statuses">
                                        <v-icon :class="{['text-'+item.color]: !!item.color}" :icon="item.icon" :title="item.hint"
                                                class="fs-4"/>
                                    </v-collection>
                                </div>
                                <div :class="{['bg-'+item.statuses.lead_status.color]: true}" class="lead-status">
                                    {{ item.statuses.lead_status.hint }}
                                </div>
                                <div v-if="item.withMobileApp" class="text-center fw-700 p-1 text-uppercase text-xs vert-middle"> <span class="text-lg"> &bull;</span>	Aplikacja</div>
                            </v-column>
                            <v-column class="text-end" label="Oferty">
                                <span class="d-block number">{{ item.active_applications_offers_count }}</span>
                                <span class="d-block unit">szt.</span>
                            </v-column>
                            <v-column class="text-end" label="Wypłacono">
                                <span class="d-block number">{{ item.applications_paid_count }}</span>
                                <span class="d-block unit">szt.</span>
                            </v-column>
                            <v-column class="text-end" label="Kwota">
                                <span class="number">{{ item.amount }}</span>
                                <span class="d-block unit">PLN</span>
                            </v-column>
                            <v-column class="text-end" label="Okres">
                                <span class="number">{{ item.period }}</span>
                                <span class="d-block unit">msc</span>
                            </v-column>

                            <v-column class="text-center" label="Status rozmowy">
                                <div class="row" style="width: 90px; margin: 0 auto">
                                    <v-collection v-slot="{item}" :items="item.statuses.action_statuses">
                                        <v-icon :class="{['text-'+item.color]: !!item.color}" :icon="item.icon" :title="item.hint"
                                                class="fs-4 col-md-4"/>
                                    </v-collection>
                                </div>
                            </v-column>
                            <v-column class="actions">
                                <v-button v-if="item.continueLeadBtn" :href="item.redirect_url" color="gold" icon="feedback"
                                        label="Uzupełnij wniosek"/>
                                <v-button v-else
                                        :route="{name:'agent.leads.preview', params: {lead: item.uid}}" color="primary"
                                        icon="file-add-line-1" label="Szczegóły wniosku"/>

                            </v-column>
                        </v-table>
                    </div>
                </div>


            </v-model.client.leads>
        </v-model.client>
    </section>
</template>

<script>
export default {
    name: "route-agent.clients.preview",
    view: 'layer',
    route: {
        path: 'szczegoly/:client',
        title: "Dane klienta"
    },
    data() {
        return {
            advanced: false
        }
    }
}
</script>

<style scoped>

</style>