<template>
<section>
  <div class="d-flex justify-content-between">
    <div class="hgroup">
      <h1>Wystawianie faktury</h1>
      <h2>To Twoje centrum rozliczenia</h2>
    </div>
  </div>
  <v-model.invoice v-slot="{model, fetch, loading}">
    <div class="card">
      <div class="card-body">
        <div class="label-text">
          <div class="row mb-4">
            <div class="col-12">
              <p>
                <span class="label">Faktura numer</span>
                <span class="text"><h3>{{ model.invoice.invoice_number }}</h3></span>
              </p>
            </div>
            <div class="col-12">
              <div class="invoice-view-mode p-3">
                <h5 class="d-block invoice-view-mode-header">Tryb podglądu</h5>
                <span class="invoice-view-mode-content">Faktura nie została jeszcze wystawiona. Sprawdź dane i użyj przycisku kiedy będzie dostępny.</span>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <p>
                <span class="label">Miejscowość i data wystawienia</span>
                <span class="text-black">{{ model.invoice.city }}, {{ model.invoice.created_at }}</span>
              </p>
            </div>
            <div class="col">
              <p>
                <span class="label">Data sprzedaży</span>
                <span class="text-black">{{ model.invoice.sold_at }}</span>
              </p>
            </div>
            <div class="col">
              <p>
                <span class="label">Termin płatności</span>
                <span class="text-black">{{ model.invoice.pay?.at }} ({{ model.invoice.pay?.in }} dni)</span>
              </p>
            </div>
            <div class="col">
              <p>
                <span class="label">Forma płatności</span>
                <span class="text-black">{{ model.invoice.pay?.method }}</span>
              </p>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col">
              <p>
                <span class="label">Sprzedawca</span>
                <span class="text-black"><h4 class="font-weight-bold">{{ model.invoice.seller?.name }}</h4></span>
                <span class="text-black">ul. {{ model.invoice.seller?.address?.street }} {{ model.invoice.seller?.address?.building_no }}/{{ model.invoice.seller?.address?.local_no}}</span>
                <span class="text-black d-block">{{ model.invoice.seller?.address?.postalcode }} {{ model.invoice.seller?.address?.city }}</span>
                <span class="text-black d-block">NIP: {{ model.invoice.seller?.nip }}</span>
                <span class="text-black d-block">Numer rachunku: {{ model.invoice.seller?.iban }}</span>
              </p>
            </div>
            <div class="col">
              <p>
                <span class="label">Nabywca</span>
                <span class="text-black"><h4 class="font-weight-bold">{{ model.invoice.buyer?.name }}</h4></span>
                <span class="text-black">ul. {{ model.invoice.buyer?.address?.street }} {{ model.invoice.buyer?.address?.building_no }}/{{ model.invoice.buyer?.address?.local_no}}</span>
                <span class="text-black d-block">{{ model.invoice.buyer?.address?.postalcode }} {{ model.invoice.buyer?.address?.city }}</span>
                <span class="text-black d-block">NIP: {{ model.invoice.buyer?.nip }}</span>
                <span class="text-black d-block">Numer rachunku: {{ model.invoice.buyer?.iban }}</span>
              </p>
            </div>
          </div>
          <div class="row mx-1">
            <v-invoice-table v-slot="{item}" :items="model.invoice.items" :invoice-data="model.invoice">
              <v-column label="LP">
                <span class="lp">{{ item.lp }}</span>
              </v-column>
              <v-column label="Nazwa towaru / usługi" style="width: 400px">
                <span>{{ item.name }}</span>
              </v-column>
              <v-column class="text-center" label="Ilość">
                <span class="number">{{ item.quantity }}</span>
                <span class="d-block unit">szt</span>
              </v-column>
              <v-column label="Cena netto za 1 szt.">
                <span class="number">{{ item.quantity_netto_price_formatted }}</span>
                <span class="d-block unit">PLN</span>
              </v-column>
              <v-column label="VAT">
                <span class="number">{{ item.vat?.type }}</span>
              </v-column>
              <v-column label="Wartość VAT">
                <span class="number">{{ item.vat?.value_formatted  }}</span>
              </v-column>
              <v-column label="Wartość netto">
                <span class="number">{{ item.netto_formatted  }}</span>
                <span class="d-block unit">PLN</span>
              </v-column>
              <v-column label="Wartość brutto">
                <span class="number">{{ item.brutto_formatted  }}</span>
                <span class="d-block unit">PLN</span>
              </v-column>
            </v-invoice-table>
          </div>
          <div class="row text-end mb-4">
            <div class="d-flex justify-content-end flex-row align-items-center gap-2 my-2">
              <span class="font-weight-bold text col-1">Wartość netto</span>
              <span class="summary-number col-1">{{ model.invoice.netto_value_formatted  }} <span class="summary-unit">PLN</span></span>
            </div>
            <div class="d-flex justify-content-end flex-row align-items-center gap-2 my-2">
              <span class="font-weight-bold text col-1">Wartość VAT</span>
              <span class="summary-number col-1">{{ model.invoice.vat_value_formatted  }} <span class="summary-unit">PLN</span></span>
            </div>
            <div class="d-flex justify-content-end flex-row align-items-center gap-2 my-2">
              <span class="font-weight-bold text col-1">Wartość brutto</span>
              <span class="summary-number col-1">{{ model.invoice.brutto_value_formatted  }} <span class="summary-unit">PLN</span></span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-2">
              <p>
                <span class="label">Całkowita kwota do zapłaty</span>
                <span class="summary-number-big col-1">{{ model.invoice.to_pay?.value_formatted  }} <span class="summary-unit-big">PLN</span></span>
              </p>
            </div>
            <div class="col-3">
              <p>
                <span class="label">Słownie</span>
                <span class="text-black">{{ model.invoice.to_pay?.in_words }}</span>
              </p>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-2">
              <p>
                <span class="label">Imię i nazwisko wydawcy</span>
                <span class="text-black">{{ model.invoice.seller?.name }}</span>
              </p>
            </div>
            <div class="col-3">
              <p>
                <span class="label">Imię i nazwisko odbiorcy</span>
                <span class="text-black">{{ model.invoice.buyer?.name }}</span>
              </p>
            </div>
          </div>
          <div class="row mb-4 mx-1">
            <div class="col border-bottom"></div>
          </div>
          <div class="row">
            <div class="col d-flex justify-content-end gap-2">
              <v-control-hidden v-model="model.invoice.invoice_number"/>
              <v-control-hidden v-model="model.invoice.city"/>
              <v-model.invoice.new.earnings v-slot="{downloadReport}" v-if="model.invoice.to_pay?.value > 0 && model.invoice.seller">
                <div :class="{'report-download': this.reportPage}">
                  <v-button :outline="true" id="report-download" color="primary" size="lg" icon="download-line" label="Pobierz raport" @click="downloadReport"/>
                </div>
              </v-model.invoice.new.earnings>
              <v-model.earnings.report v-slot="{fetchOnClick}" v-else>
                <div :class="{'report-download': this.reportPage}">
                  <v-button :outline="true" id="report-download" color="primary" size="lg" icon="download-line" label="Pobierz raport" @click="fetchOnClick"/>
                </div>
              </v-model.earnings.report>
              <template v-if="model.invoice.to_pay?.value > 0 && model.invoice.seller">
                <v-button submit color="gold" size="lg" icon="file-paper" :loading="loading" label="Zatwierdź fakturę"/>
              </template>
              <template v-else>
                <v-button color="gold" :disabled="true" size="lg" label="Nie można wystawić faktury" icon="feedback" style="cursor: not-allowed" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-model.invoice>
</section>
</template>
<script>
import VButton from "@/components/Button.vue";
import VControlHidden from "@/components/Control/Hidden.vue";

export default {
  name: "route-agent.invoice",
  components: {VControlHidden, VButton},
  route: {
    path: '/faktura',
    title: 'Wystawianie faktury'
  },
  data() {
    return {
      reportPage: false,
    }
  },
  mounted() {
    if (this.$route.query.report) {
      setTimeout(function () {
        let idOfReport = document.getElementById('report-download');
        idOfReport.scrollIntoView();
      }, 500);
      this.setReportPage();
    }
  },
  methods: {
    setReportPage() {
      this.reportPage = true;
    }
  }
}
</script>
<style>
@keyframes selectedButton {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.report-download button {
  animation: selectedButton 1.5s;
}
</style>