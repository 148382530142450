<template>
  <input type="hidden" v-model="value">
</template>
<script>
import control from "@/mixins/control";

export default {
    name: "v-control-hidden",
    mixins: [control],
}
</script>

<style scoped>

</style>
