<template>
  <section>
    <v-model.earnings.periods v-slot="{model, query, fetch, clearQuery, pagination, search}">
      <div class="d-flex justify-content-between">
        <div>
          <v-button class="px-0 py-2" color="transparent" icon="arrow-left-s"
                    @click="$router.go(-1)"/>
        </div>
        <div class="hgroup flex-grow-1">
          <h1>Okresy rozliczeniowe</h1>
          <h2>To Twoje centrum dowodzenia</h2>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <span class="card-title">Twoje ostatnie faktury</span>

          <v-table v-slot="{item}" :items="model.periods">
            <v-column label="Numer">
              <span class="font-weight-bold">{{ item.invoice_number ?? 'brak' }}</span>
            </v-column>
            <v-column label="Data wystawienia">
              <div v-if="item.invoice" class="d-inline-block text-end">
                <v-date :date="item.invoice.created_at" class="d-block" format="YYYY/MM/DD"/>
                <v-date :date="item.invoice.created_at" class="d-block" format="HH:mm"/>
              </div>
              <div v-else class="d-inline-block text-end">
                <span>nie jest wystawiona</span>
              </div>
            </v-column>
            <v-column label="Okres" class="text-end">
              <span class="number">{{ item.label.substring(0, item.label.indexOf(' ')) }}</span>
              <span class="d-block unit">{{ item.label.substring(item.label.indexOf(' ') + 1) }}</span>
            </v-column>
            <v-column label="Kwota" class="text-end">
              <span class="number">{{ item.amount_formatted ?? item.amount }}</span>
              <span class="d-block unit">PLN</span>
            </v-column>
            <v-column label="Status">
              <div :class="{['bg-'+item.status.bgColor]: true, ['text-'+item.status.textColor]: true}" class="lead-status"
                   style="width: 140px; margin: 0;">
                {{ item.status.label }}
              </div>
            </v-column>
            <v-column label="Pobierz" class="text-start earnings-buttons" style="width: 300px">
              <div class="d-flex gap-2 justify-content-start align-items-center flex-row">
                <template v-if="item.invoice">
                  <div>
                    <v-button color="secondary" icon="feedback" v-if="item.invoice.uid"
                              label="Pokaż fakturę"
                              :route="{name: 'agent.invoice-preview', params: {'invoice': item.invoice.uid}}"
                              size="xs"/>
                  </div>
                  <div>
                    <v-button color="secondary" icon="file-list" v-if="item.invoice.uid"
                              label="Pokaż raport"
                              :route="{name: 'agent.invoice-earnings', params: {'invoice': item.invoice.uid}}"
                              size="xs"/>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <template v-if="item.canBeInvoiced">
                      <v-button color="gold" icon="feedback"
                                label="Wystaw fakturę"
                                :route="{name:'agent.invoice'}"
                                size="xs"/>
                    </template>
                    <template v-else>
                      <v-button color="gold" icon="feedback"
                                label="Wystaw fakturę"
                                :disabled="true"
                                size="xs"/>
                    </template>
                  </div>
                  <div>
                    <template v-if="item.canBeInvoiced">
                      <v-button color="secondary" icon="file-list"
                                label="Pokaż raport"
                                :route="{name:'agent.new-invoice-earnings'}"
                                size="xs"/>
                    </template>
                    <template v-else>
                      <v-button color="secondary" icon="file-list"
                                label="Bieżący okres"
                                :route="{name:'agent.earnings.current'}"
                                size="xs"/>
                    </template>


                  </div>
                </template>
              </div>
            </v-column>
          </v-table>

          <v-pagination :pagination="pagination"/>
        </div>
      </div>
    </v-model.earnings.periods>
  </section>
</template>
<script>

import VButton from "@/components/Button.vue";
import VControlToggle from "@/components/Control/Toggle.vue";
import VColumn from "@/components/Column.vue";

export default {
  name: "route-agent.earnings.invoices",
  components: {VColumn, VControlToggle, VButton},
  view: "layer",
  route: {
    path: '/rozliczenia/okresy-rozliczeniowe',
    title: 'Okresy rozliczeniowe'
  },
}
</script>
<style scoped>
.earnings-buttons a {
  width: 140px !important;
}
</style>