import model from '../mixins/model';

export default {
    mixins: [model],
    query() {
        return {
            'is_paid': { equal: null },
            'invoice_number': { equal: null },
            'created_at': { start: null, end: null },
        }
    },
    model() {
        return {
            invoices: [],
            months: [],
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.dispatch((resolve, reject) => {
                this.$http.request({ url: 'earnings/invoices', params: {...this.query} })
                    .then((response) => {
                        this.model.invoices = response.data.invoices
                        this.model.months = response.data.months
                    })
                    .then(resolve)
                    .catch(reject);
            });
        },
    }
}