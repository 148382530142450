<template>
  <section>
    <div class="d-flex justify-content-between">
      <div class="hgroup">
        <h1>Rozliczenia</h1>
        <h2>To Twoje centrum rozliczenia</h2>
      </div>
    </div>
    <v-model.earnings.overview v-slot="{model,query, fetch, clearQuery, pagination}">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <span class="card-title">Przegląd rozliczeń</span>
              <div class="row">
                <div class="col-md-4 text-start">
                  <span>Zarobiłeś z nami</span>
                  <div class="row">
                    <span class="number">{{ model.summary.earned }} <span class="unit">PLN</span></span>
                  </div>
                </div>
                <div class="col-md-4 text-start">
                  <span>Wypłaciłeś do dziś</span>
                  <div class="row">
                    <span class="number">{{ model.summary.invoiced }} <span class="unit">PLN</span></span>
                  </div>
                </div>
                <div class="col-md-4">
                  <span>Możesz teraz wypłacić</span>
                  <div class="row">
                    <span :class="{'number': true, 'text-success-light': model.summary.topay > 0, 'text-danger': model.summary.topay <= 0}">{{ model.summary.topay }} <span class="unit">PLN</span></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 text-end d-flex justify-content-start align-items-end flex-column">
              <template v-if="model.summary.canInvoice">
                <v-button color="gold" size="lg" label="Wystaw fakturę" icon="feedback" :route="{name:'agent.invoice'}" />
                <div class="d-block">
                  Za
                  <template v-for="(month, index) in model.months">
                    {{ month }}<template v-if="index !== model.months.length - 1">, </template>
                  </template>
                </div>
              </template>
              <template v-else>
                <v-button color="gold" :disabled="true" size="lg" label="Nie można wystawić faktury" icon="feedback" style="cursor: not-allowed" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </v-model.earnings.overview>

    <v-model.earnings v-slot="{model, fetch}">
      <div class="card">
        <div class="card-body">
          <span class="card-title d-flex justify-content-between">
            Bieżący okres rozliczeniowy ({{ model.month }})
            <v-model.earnings.report v-slot="{fetchOnClick}">
              <v-button color="primary" @click="fetchOnClick" size="lg" label="Pobierz raport" icon="file-list" />
            </v-model.earnings.report>
          </span>
          <v-table v-slot="{item}" :items="model.earnings.slice(0,5)">
            <v-column label="Nr wniosku">
              <span>#{{ item.hashid }}</span>
            </v-column>
            <v-column label="Produkt">
              <img :alt="item.productable.name" :src="item.productable.thumbnail" class="logo-image-min2"/>
            </v-column>
            <v-column label="Konsultant">
              <v-agent :agent="item.user"/>
            </v-column>
            <v-column label="Klient">
              <div class="d-inline-block text-start">
                <div class="d-block font-weight-bold">{{ item.application.client.firstName }}</div>
                <div class="d-block font-weight-bold">{{ item.application.client.lastName }}</div>
              </div>
            </v-column>
            <v-column label="Kwota pożyczki" class="text-end">
              <span class="number">{{ item.application_amount_formatted ?? item.application.amount }}</span>
              <span class="d-block unit">{{ item.product.amount_label }}</span>
            </v-column>
            <v-column label="Prowizja" class="text-end">
              <span class="number" style="word-spacing: -5px">
                {{ item.commission.value_formatted ?? item.commission.value }}
                <span class="d-block unit" v-if="item.commission.type !== '%'">{{ item.commission.type }}</span>
                <span v-else>{{ item.commission.type }}</span>
              </span>
            </v-column>
            <v-column label="Należna kwota" class="text-end">
              <span class="number">{{ item.payout_formatted ?? item.payout }}</span>
              <span class="d-block unit">{{ item.product.amount_label }}</span>
            </v-column>
            <v-column label="Data" class="text-end">
              <div class="d-inline-block text-end">
                <v-date :date="item.created_at" class="d-block" format="YYYY/MM/DD"/>
                <v-date :date="item.created_at" class="d-block" format="HH:mm"/>
              </div>
            </v-column>
          </v-table>

          <span class="d-flex justify-content-end gap-2 align-items-center">
            Nie znalazłeś szukanego rozliczenia?
            <v-button :outline="true" color="secondary"
                      :route="{name:'agent.earnings.current'}"
                      label="Pokaż wszystkie"
                      size="xs" style="padding: 0px 40px"/>
          </span>
        </div>
      </div>
    </v-model.earnings>
    <v-model.earnings.limit.periods v-slot="{model,query, fetch, clearQuery, pagination}">
      <div class="card">
        <div class="card-body">
          <span class="card-title">Poprzednie okresy rozliczeniowe</span>

          <v-table v-slot="{item}" :items="model.periods">
            <v-column label="Numer">
              <span class="font-weight-bold">{{ item.invoice_number ?? 'brak' }}</span>
            </v-column>
            <v-column label="Data wystawienia">
              <div v-if="item.invoice" class="d-inline-block text-end">
                <v-date :date="item.invoice.created_at" class="d-block" format="YYYY/MM/DD"/>
                <v-date :date="item.invoice.created_at" class="d-block" format="HH:mm"/>
              </div>
              <div v-else class="d-inline-block text-end">
                <span>nie jest wystawiona</span>
              </div>
            </v-column>
            <v-column label="Okres" class="text-end">
              <span class="number">{{ item.label.substring(0, item.label.indexOf(' ')) }}</span>
              <span class="d-block unit">{{ item.label.substring(item.label.indexOf(' ') + 1) }}</span>
            </v-column>
            <v-column label="Kwota" class="text-end">
              <span class="number">{{ item.amount_formatted ?? item.amount }}</span>
              <span class="d-block unit">PLN</span>
            </v-column>
            <v-column label="Status">
              <div :class="{['bg-'+item.status.bgColor]: true, ['text-'+item.status.textColor]: true}" class="lead-status"
                   style="width: 140px; margin: 0;">
                {{ item.status.label }}
              </div>
            </v-column>
            <v-column label="Pobierz" class="text-start earnings-buttons" style="width: 300px">
              <div class="d-flex gap-2 justify-content-start align-items-center flex-row">
                <template v-if="item.invoice">
                  <div>
                    <v-button color="secondary" icon="feedback" v-if="item.invoice.uid"
                              label="Pokaż fakturę"
                              :route="{name: 'agent.invoice-preview', params: {'invoice': item.invoice.uid}}"
                              size="xs"/>
                  </div>
                  <div>
                    <v-button color="secondary" icon="file-list" v-if="item.invoice.uid"
                              label="Pokaż raport"
                              :route="{name: 'agent.invoice-earnings', params: {'invoice': item.invoice.uid}}"
                              size="xs"/>
                  </div>
                </template>
                <template v-else>
                  <div v-if="item.amount > 0">
                    <v-button color="gold" icon="feedback"
                              label="Wystaw fakturę"
                              :route="{name:'agent.invoice'}"
                              size="xs"/>
                  </div>
                  <div v-else>
                    <v-button color="gold" icon="feedback"
                              label="Wystaw fakturę"
                              :disabled="true"
                              size="xs"/>
                  </div>
                  <div v-if="item.amount > 0">
                    <v-button color="secondary" icon="file-list"
                              label="Pokaż raport"
                              :route="{name:'agent.new-invoice-earnings'}"
                              size="xs"/>
                  </div>
                  <div v-else>
                    <v-button color="secondary" icon="file-list"
                              label="Bieżący okres"
                              :route="{name:'agent.earnings.current'}"
                              size="xs"/>
                  </div>
                </template>
              </div>
            </v-column>
          </v-table>

          <span class="d-flex justify-content-end gap-2 align-items-center">
            Nie znalazłeś szukanego rozliczenia?
            <v-button :outline="true" color="secondary"
                      label="Pokaż wszystkie"
                      :route="{name:'agent.earnings.periods'}"
                      size="xs" style="padding: 0px 40px"/>
          </span>
        </div>
      </div>
    </v-model.earnings.limit.periods>
  </section>
</template>
<script>
import VColumn from "@/components/Column.vue";
import VButton from "@/components/Button.vue";

export default {
  name: "route-agent.earnings",
  components: {VButton, VColumn},
  route: {
    path: '/rozliczenia',
    title: 'Rozliczenia'
  },
}
</script>
<style scoped>
.earnings-buttons a {
  width: 140px !important;
}
</style>