import model from '../mixins/model';
import moment from "moment";

export default {
    mixins: [model],
    query() {
        return {
            'created_at': {start: null, end: null},
            'application_type': { in: [] },
            'user_id': { in: [] },
            'source_product_id': { in: [] }
        }
    },
    model() {
        return {
            invoices: [],
            select: [],
            invoice: []
        }
    },
    mounted() {
        this.fetch();
    },
    watch: {
        'query.from_at': {
            handler() {
                let start = moment().startOf('day');

                switch (this.query.from_at) {
                    case '7days':
                        start =  start.subtract(7, 'days').utc().format();
                        break;
                    case '3days':
                        start = start.subtract(3, 'days').utc().format();
                        break;
                    case 'yesterday':
                        start = start.subtract(1, 'days').utc().format();
                        break;
                    case 'today':
                        start = start.utc().format();
                        break;
                    case 'month':
                    default:
                        start = null;
                        break;
                }
                this.query.created_at = {start,end:null};
            }
        }
    },
    methods: {
        clearQuery() {
            this.query = this.$options.query();
            this.fetch();
        },
        fetch() {
            this.dispatch((resolve, reject) => {
                this.$router.push({query: this.query}).then(() => {
                    this.$http.request({ url: 'earnings/new-invoice/report', params: {...this.query}})
                        .then((response) => {
                            this.model.invoices = response.data.invoices;
                            this.model.select = response.data._select;
                            this.model.invoice = response.data.invoice;
                            this.pagination = response.data.pagination
                        })
                        .then(resolve)
                        .catch(reject);
                });
            });
        },
        downloadReport() {
            this.dispatch((resolve, reject) => {
                this.$http.request({url: 'earnings/new-invoicePdf/report', responseType: 'arraybuffer'})
                    .then((response) => {
                        let link = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                        let fileLink = document.createElement('a');

                        fileLink.href = link;
                        fileLink.setAttribute('download', 'Raport-z-faktury.pdf');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .then(resolve)
                    .catch(reject);
            }, false);
        }
    }
}