import model from "@/mixins/model";
import QRCode from 'qrcode';
import jsPDF from "jspdf";
import {createApp, h, render} from "vue";

import InvitationMail from '../shared/mail/invitation';

export default {
    mixins: [model],
    props: {
        socialMainBannerSrc: {},
        socialCardBannerSrc: {},
        qrWrapperSrc: {}
    },
    model() {

        return {
            templateSMS: 'Witaj! Tu ' + this.$auth.user?.firstName + ' ' + this.$auth.user?.lastName + ' - Twój osobisty Agent Loando Direct.\nZapraszam Cię do skorzystania z aplikacji. \nKliknij w LINK i pobierz aplikację na swój telefon.',
            templateMail: '👋 Witaj! Tu ' + this.$auth.user?.firstName + ' ' + this.$auth.user?.lastName + ' - Twój osobisty Agent Loando Direct. Zapraszam Cię do skorzystania z aplikacji. 📱📱\nKliknij w LINK i pobierz aplikację na swój telefon.',
            templateFb: 'Witaj! Tu ' + this.$auth.user?.firstName + ' ' + this.$auth.user?.lastName + ' - Twój osobisty Agent Loando Direct. \nZapraszam Cię do skorzystania z aplikacji. \nKliknij w LINK i pobierz aplikację na swój telefon.'
        }
    },
    data() {
        return {
            socialMainBannerImage: this.image(this.socialMainBannerSrc),
            socialCardBannerImage: this.image(this.socialCardBannerSrc),
            qrWrapperImage: this.image(this.qrWrapperSrc),
            avatarImage: this.image(this.$auth.user.avatar),
            avatarDataUrl: null,

            qrCodeCanvas: null,
            socialMainBannerCanvas: null,
            socialCardBannerCanvas: null,
            socialMainBannerDataUrl: null,
            ready: false
        }

    },
    mounted() {
        this.makeAvatarCanvas();

        const font = new FontFace('IBM Plex Sans', 'url_fontu');
    },
    computed: {
        contentHtmlMail() {

            const k = document.createElement('div');

            createApp(InvitationMail, {
                content: this.model.templateMail,
                firstName: this.$auth.user.firstName,
                lastName: this.$auth.user.lastName,
                link: this.agentLink,
                avatarUrl: this.avatarDataUrl
            }).mount(k);

            return k.innerHTML;
        },
        parsedTemplateSMS() {
            return this.model.templateSMS.replace('LINK', this.agentLink);
        },
        parsedTemplateFb() {
            return this.model.templateSMS.replace('LINK', this.agentLink);
        },
        qrCodeAdToPdfFilename() {
            return this.$auth.user.username.slug() + '-' + this.agentCode.slug() + '-plakietka-kod-qr.pdf';
        },
        qrCodeAdFilename() {
            return this.$auth.user.username.slug() + '-' + this.agentCode.slug() + '-kod-qr-1500x1500.png';
        },
        mainBannerFilename() {
            return this.$auth.user.username.slug() + '-' + this.agentCode.slug() + '-banner-600x600.png';
        },
        cardBannerFilename() {
            return this.$auth.user.username.slug() + '-' + this.agentCode.slug() + '-banner-600x314.png';
        },
        agentCode() {
            return this.$auth.user.qrcode?.toUpperCase ? this.$auth.user.qrcode?.toUpperCase() : 'XYZ123';
        },
        agentLink() {
            return this.$http.url('loando', 'a/' + this.agentCode);
        }
    },
    methods: {
        makeAvatarCanvas() {
            const canvas = document.createElement("canvas");
            canvas.width = 60;
            canvas.height = 60;
            const ctx = canvas.getContext('2d');

            const onAvatarLoad = () => {

                ctx.drawImage(this.avatarImage, 0, 0, 60, 60);
                this.ready = true;
                this.$forceUpdate();

                this.avatarDataUrl = canvas.toDataURL();

            }

            this.avatarImage.addEventListener('load', onAvatarLoad);
            if (this.avatarImage) onAvatarLoad();


        },
        image(src) {
            const image = new Image();
            image.src = src;
            image.crossOrigin = 'anonymous';
            return image;
        },
        copyTemplateSMS() {
            navigator.clipboard.writeText(this.parsedTemplateSMS);

            this.snack.info('Treść SMS został skopiowany do schowka', 'Informacja');
        },
        copyTemplateMail() {

            navigator.clipboard.writeText(this.contentHtmlMail);


            this.snack.info('Treść maila została skopiowana do schowka', 'Informacja');
        },
        copyTemplateFB() {
            navigator.clipboard.writeText(this.parsedTemplateFb);

            this.snack.info('Treść komunikatu Facebook skopiowano do schowka', 'Informacja');
        },
        copyLink() {
            navigator.clipboard.writeText(this.agentLink);

            this.snack.info('Twój link został skopiowany do schowka', 'Informacja');
        },
        qrCodeAdToPdf() {
            const pdf = new jsPDF();

            pdf.addImage(this.qrCodeCanvas, 'PNG', 10, 10, 95, 146);
            pdf.save(this.qrCodeAdToPdfFilename);

            this.snack.info('Plakietka do druku jest w trakcie pobierania. Zapisz plik na swoim komputerze.', 'Informacja');
        },
        qrCodeDownload() {
            QRCode.toCanvas(this.agentLink, {
                width: 1500,
                height: 1500, errorCorrectionLevel: 'Q'
            }, (error, canvas) => {

                const link = document.createElement('a');
                link.download = this.qrCodeAdFilename;
                link.href = canvas.toDataURL('image/png');
                link.click();

                this.snack.info('Twój Unikalny Kod QR jest w trakcie pobierania. Zapisz plik na swoim komputerze.', 'Informacja');

            });
        },
        socialBannerDownload() {

            const link1 = document.createElement('a');
            link1.download = this.mainBannerFilename;
            link1.href = this.socialMainBannerCanvas.toDataURL('image/png');
            link1.click();

            setTimeout(() => {
                const link2 = document.createElement('a');
                link2.download = this.cardBannerFilename;
                link2.href = this.socialCardBannerCanvas.toDataURL('image/png');
                link2.click();
            });


            this.snack.info('Twoje grafiki Facebook są w trakcie pobierania. Zapisz pliki na swoim komputerze.', 'Informacja');
        },
        qrCodeAd(ctx, canvas, {width, height}) {

            this.qrCodeCanvas = canvas;

            const redraw = () => {
                ctx.drawImage(this.qrWrapperImage, 0, 0, width, height);

                const fontSize = 0.046 * width;
                const letterSpacing = 0.013 * width;

                QRCode.toCanvas(this.agentLink, {
                    color: {
                        dark: '#000000',
                        light: '#FFFFFF00'
                    },
                    width: 256,
                    height: 256, errorCorrectionLevel: 'Q'
                }, (error, canvas) => {
                    if (error) console.error(error)
                    ctx.drawImage(canvas, 0.23 * width, 0.31 * height, 0.54 * width, 0.54 * width);

                    ctx.font = `500 ${fontSize}px 'IBM Plex Sans'`
                    ctx.fillStyle = "#FFFFFF";
                    ctx.textAlign = 'center';
                    ctx.letterSpacing = `${letterSpacing}px`;
                    ctx.fillText(this.agentCode, 0.5 * width, 0.696 * height, width * 0.6);


                })

                if(!this.qrWrapperImage.complete) setTimeout(() => redraw(), 100);
                if(!document.fonts.check("12px 'IBM Plex Sans'")) setTimeout(() => redraw(), 100);
            };

            redraw();

        },
        socialMainBanner(ctx, canvas, {width, height}) {


            this.socialMainBannerCanvas = canvas;

            const redraw = () => {

                ctx.clearRect(0, 0, width, height)
                ctx.drawImage(this.socialMainBannerImage, 0, 0, width, height);

                const fontSize1 = 0.021 * width;
                const fontSize2 = 0.028 * width;
                const fontSize3 = 0.014 * width;
                const letterSpacing = 0.003 * width;

                ctx.font = `400 ${fontSize1}px 'IBM Plex Sans'`
                ctx.fillStyle = "#F6B43A";
                ctx.textAlign = 'center';
                ctx.fillText("Chętnie pomogę!", 0.795 * width, 0.19 * height, width * 0.6);


                ctx.font = `500 ${fontSize2}px 'IBM Plex Sans'`
                ctx.fillStyle = "#FFF";
                ctx.textAlign = 'center';
                ctx.fillText(this.$auth.user.firstName, 0.795 * width, 0.235 * height, width * 0.6);
                ctx.fillText(this.$auth.user.lastName, 0.795 * width, 0.27 * height, width * 0.6);

                ctx.font = `600 ${fontSize3}px 'IBM Plex Sans'`
                ctx.fillStyle = '#96989E';
                ctx.letterSpacing = `${letterSpacing}px`;
                ctx.fillText("AGENT LOANDO", 0.795 * width, 0.31 * height, width * 0.6);

                ctx.beginPath();
                ctx.roundRect(0.740 * width, 0.04 * height, 0.11 * width, 0.11 * width, 0.015 * width);
                ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
                ctx.shadowBlur = 10;
                ctx.fill();

                ctx.beginPath();
                ctx.save()
                ctx.roundRect(0.740 * width, 0.04 * height, 0.11 * width, 0.11 * width, 0.015 * width);
                ctx.clip();
                ctx.drawImage(this.avatarImage, 0.740 * width, 0.04 * height, 0.11 * width, 0.11 * width);
                ctx.restore();

                if(!this.socialMainBannerImage.complete) setTimeout(() => redraw(), 100);
                if(!document.fonts.check("12px 'IBM Plex Sans'")) setTimeout(() => redraw(), 100);
            }

            redraw()

        },
        socialCardBanner(ctx, canvas, {width, height}) {

            this.socialCardBannerCanvas = canvas;
            const redraw = () => {

                ctx.clearRect(0, 0, width, height)
                ctx.drawImage(this.socialCardBannerImage, 0, 0, width, height);

                const fontSize1 = 0.035 * width;
                const fontSize2 = 0.028 * width;
                const fontSize3 = 0.019 * width;
                const letterSpacing = 0.002 * width;
                ctx.beginPath();
                ctx.font = `600 ${fontSize1}px 'IBM Plex Sans'`
                ctx.fillStyle = "#F6B43A";
                ctx.textAlign = 'left';
                ctx.fillText('CHĘTNIE POMOGĘ', 0.06 * width, 0.91 * height, width * 0.6);

                ctx.font = `500 ${fontSize2}px 'IBM Plex Sans'`
                ctx.fillStyle = "#FFF";
                ctx.textAlign = 'right';
                ctx.fillText(this.$auth.user.firstName + ' ' + this.$auth.user.lastName, 0.84 * width, 0.89 * height, width * 0.6);
                //
                ctx.font = `600 ${fontSize3}px 'IBM Plex Sans'`
                ctx.fillStyle = '#96989E';
                ctx.letterSpacing = `${letterSpacing}px`;
                ctx.fillText("AGENT LOANDO", 0.84 * width, 0.94 * height, width * 0.6);
                ctx.closePath();
                //
                ctx.beginPath();
                ctx.roundRect(0.87 * width, 0.815 * height, 0.08 * width, 0.08 * width, 0.015 * width);
                ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
                ctx.shadowBlur = 10;
                ctx.fill();
                ctx.closePath();
                //
                //
                ctx.beginPath();
                ctx.roundRect(0.870 * width, 0.815 * height, 0.08 * width, 0.08 * width, 0.015 * width);
                ctx.save();
                ctx.clip();
                ctx.drawImage(this.avatarImage, 0.870 * width, 0.815 * height, 0.08 * width, 0.08 * width);
                ctx.restore();
                ctx.closePath();

                if(!this.socialCardBannerImage.complete) setTimeout(() => redraw(), 100);
                if(!document.fonts.check("12px 'IBM Plex Sans'")) setTimeout(() => redraw(), 100);
            }

            redraw();

            // this.socialCardBannerImage.onload = draw;
            // if(this.socialCardBannerImage.complete) draw();
        }


    }
}