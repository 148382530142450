<template>
  <section>
    <v-model.earnings.current v-slot="{model, query, fetch, clearQuery, pagination, search}">
      <div class="d-flex justify-content-between">
        <div>
          <v-button class="px-0 py-2" color="transparent" icon="arrow-left-s"
                    @click="$router.go(-1)"/>
        </div>
        <div class="hgroup flex-grow-1">
          <h1>Bieżący okres rozliczeniowy ({{ model.month }})</h1>
          <h2>To Twoje centrum dowodzenia - podsumowanie działań z miesiąca <b><u>{{ model.month }}</u></b></h2>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <span class="card-title d-flex justify-content-between">
            Filtruj wyniki
            <v-button @click="clearQuery" :outline="true" color="secondary"  label="Wyczyść filtry" size="xs" icon="file-add-line-1"/>
          </span>

          <div class="d-flex align-items-start">
            <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                              :options="[null,'today']" class="btn-xs me-1 mt-4"
                              label="Dziś"/>
            <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                              :options="[null,'yesterday']" class="btn-xs mx-1 mt-4"
                              label="Wczoraj"/>
            <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                              :options="[null,'3days']" class="btn-xs mx-1 mt-4"
                              label="3 dni"/>
            <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                              :options="[null,'7days']" class="btn-xs mx-1 mt-4"
                              label="7 dni"/>
            <v-control-toggle v-model="query.from_at" :color="['light','primary']"
                              :options="[null,'month']" class="btn-xs mx-1 mt-4"
                              label="Miesiąc"/>

            <v-control-select class="mx-1 p-0 products-groups-select" placeholder="Wszystkie" label="Grupa produktów" :options="OffersLabelsEnum" v-model="query['application_type'].in" :multiple="true"/>
            <v-control-toggle class="mt-4" :color="isProductsToggled ? 'primary' : 'light'" size="xs" label="Lista produktów" :icon="isProductsToggled ? 'arrow-up-s' : 'arrow-down-s'" @click="toggleProductsFilter"/>
            <v-control-select class="mx-5 agents-select" placeholder="Wszystkie" label="Lista konsultantów" :options="model.select.agents" :multiple="true" v-model="query['user_id'].in"/>
          </div>
          <div v-if="isProductsToggled" class="d-flex flex-wrap mt-3 pt-2 border-top">
            <v-control-multi-toggle v-model="query['source_product_id'].in" :items="model.select.products" :is-thumbnail="true"/>
          </div>
          <div class="d-flex justify-content-end">
            <v-button @click="fetch" color="primary px-3" size="sm" label="Szukaj" icon="search" />
          </div>
        </div>
      </div>
      <v-model.earnings.overview v-slot="{model,query, fetch, clearQuery, pagination}">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <span class="card-title">Podsumowanie</span>
                <div class="row">
                  <div class="col-md-4 text-start">
                    <span>Zarobiłeś z nami</span>
                    <div class="row">
                      <span class="number">{{ model.summary.earned }} <span class="unit">PLN</span></span>
                    </div>
                  </div>
                  <div class="col-md-4 text-start">
                    <span>Wypłaciłeś do dziś</span>
                    <div class="row">
                      <span class="number">{{ model.summary.invoiced }} <span class="unit">PLN</span></span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <span>Możesz teraz wypłacić</span>
                    <div class="row">
                      <span :class="{'number': true, 'text-success-light': model.summary.topay > 0, 'text-danger': model.summary.topay < 0}">{{ model.summary.topay }} <span class="unit">PLN</span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-model.earnings.overview>
      <div class="card">
        <div class="card-body">
          <span class="card-title d-flex justify-content-between" >
            Bieżący okres rozliczeniowy ({{ model.month }})
            <v-model.earnings.report v-slot="{fetchOnClick}">
              <v-button color="primary" @click="fetchOnClick" size="lg" label="Pobierz raport" icon="file-list" />
            </v-model.earnings.report>
          </span>

          <v-table v-slot="{item}" :items="model.earnings">
            <v-column label="Nr wniosku">
              <span>#{{ item.hashid }}</span>
            </v-column>
            <v-column label="Produkt">
              <img :alt="item.productable.name" :src="item.productable.thumbnail" class="logo-image-min2"/>
            </v-column>
            <v-column label="Konsultant">
              <v-agent :agent="item.user"/>
            </v-column>
            <v-column label="Klient">
              <div class="d-inline-block text-start">
                <div class="d-block font-weight-bold">{{ item.application.client.firstName }}</div>
                <div class="d-block font-weight-bold">{{ item.application.client.lastName }}</div>
              </div>
            </v-column>
            <v-column label="Kwota pożyczki">
              <span class="number">{{ item.application_amount_formatted ?? item.application.amount }}</span>
              <span class="d-block unit">{{ item.product.amount_label }}</span>
            </v-column>
            <v-column label="Prowizja">
              <span class="number" style="word-spacing: -5px">
                {{ item.commission.value_formatted ?? item.commission.value }}
                <span class="d-block unit" v-if="item.commission.type !== '%'">{{ item.commission.type }}</span>
                <span v-else>{{ item.commission.type }}</span>
              </span>
            </v-column>
            <v-column label="Należna kwota">
              <span class="number">{{ item.payout_formatted ?? item.payout }}</span>
              <span class="d-block unit">{{ item.product.amount_label }}</span>
            </v-column>
            <v-column class="text-end" label="Data">
              <div class="d-inline-block text-end">
                <v-date :date="item.created_at" class="d-block" format="YYYY/MM/DD"/>
                <v-date :date="item.created_at" class="d-block" format="HH:mm"/>
              </div>

            </v-column>
          </v-table>

          <v-pagination :pagination="pagination"/>
        </div>
      </div>
    </v-model.earnings.current>
  </section>
</template>
<script>
import VColumn from "@/components/Column.vue";
import VButton from "@/components/Button.vue";
import VControlToggle from "@/components/Control/Toggle.vue";
import VControlMultiToggle from "@/components/Control/MultiToggle.vue";
import {OffersLabelsEnum} from "@/enums/offers/offers.label.enum";

export default {
  name: "route-agent.earnings.current",
  components: {VControlMultiToggle, VControlToggle, VButton, VColumn},
  view: "layer",
  route: {
    path: '/rozliczenia/biezacy-okres',
    title: 'Bieżący okres rozliczeniowy'
  },
  data() {
    return {
      isProductsToggled: false,
      OffersLabelsEnum: OffersLabelsEnum
    }
  },
  methods: {
    toggleProductsFilter() {
      this.isProductsToggled = !this.isProductsToggled;
    },
  }
}
</script>
<style scoped>
.products-groups-select {
  min-width: 300px;
  max-width: 400px;
}
.agents-select {
  min-width: 300px;
  max-width: 400px;
}
</style>