<template>
  <table class="table align-middle table-striped">
    <thead>
    <tr>
      <th v-for="(header,index) in headers" :key="index" :class="header.props?.class" v-bind="header.attrs">
        {{ header.props?.label }}
      </th>
    </tr>
    </thead>
    <tbody>
      <v-collection :items="items">
        <template v-slot:default="{ item }">
          <tr>
            <slot :item="item" name="default"/>
          </tr>
        </template>

        <template v-slot:empty>
          <tr>
            <td :colspan="headers.length" class="text-center">
              <p class="text-secondary p-3 mb-0"> Brak wyników </p>
            </td>
          </tr>
        </template>
        <template v-slot:loading>
          <tr>
            <td :colspan="headers.length" class="text-center bg-white">
              <v-loading text="Trwa wczytywanie..." color="secondary my-2"></v-loading>
            </td>
          </tr>
        </template>
      </v-collection>
      <tr class="invoice-include-row">
        <td></td>
        <td></td>
        <td class="text-center"><span class="number">W tym</span></td>
        <td></td>
        <td><span class="number">{{ invoiceData.vat_type }}</span></td>
        <td><span class="number">{{ invoiceData.vat_value_formatted  }}</span></td>
        <td>
          <span class="number">{{ invoiceData.items_summary?.netto_formatted  }}</span>
          <span class="d-block unit">PLN</span>
        </td>
        <td>
          <span class="number">{{ invoiceData.items_summary?.brutto_formatted  }}</span>
          <span class="d-block unit">PLN</span>
        </td>
      </tr>
      <tr class="invoice-summary-row">
        <td></td>
        <td class="text-end invoice-summary-legal pe-5">Podstawa prawna zwolnienia od podatku od towarów i<br> usług - art.  43 ust. 1 pkt. 38 ustawy o podatku VAT  z dnia<br> 11 marca 2004 roku (Dz,U. 2004 Nr 54 ze zm.)</td>
        <td class="invoice-summary-main text-center"><span class="number">Razem</span></td>
        <td class="invoice-summary-tile"></td>
        <td class="invoice-summary-tile"></td>
        <td class="invoice-summary-tile"><span class="number">{{ invoiceData.vat_value_formatted  }}</span></td>
        <td class="invoice-summary-tile">
          <span class="number">{{ invoiceData.items_summary?.netto_formatted  }}</span>
          <span class="d-block unit">PLN</span>
        </td>
        <td class="invoice-summary-tile">
          <span class="number">{{ invoiceData.items_summary?.brutto_formatted  }}</span>
          <span class="d-block unit">PLN</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "v-invoice-table",
  props: {
    items: {},
    invoiceData: {}
  },
  computed: {
    headers() {
      return this.$slots.default({item: null}).filter(x => x.type?.name === 'v-column');
    }
  }
}
</script>
