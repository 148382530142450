import model from '../mixins/model';

export default {
    mixins: [model],
    model() {
        return {
            summary: [],
            months: []
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.dispatch((resolve, reject) => {
                this.$http.request({ url: 'earnings/summary' })
                    .then((response) => {
                        this.model.summary = response.data.summary;
                        this.model.months = response.data.months;
                     })
                    .then(resolve)
                    .catch(reject);
            });
        },
    }
}