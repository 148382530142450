import model from '../mixins/model';

export default {
    mixins: [model],
    query() {
        return {
            'is_paid': { equal: null },
            'invoice_number': { equal: null },
            'created_at': { start: null, end: null },
            'month': { equal: null }
        }
    },
    model() {
        return {
            periods: [],
            months: []
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.dispatch((resolve, reject) => {
                this.$http.request({ url: 'earnings/limitedPeriods' })
                    .then((response) => {
                        this.model.periods = response.data.periods;
                    })
                    .then(resolve)
                    .catch(reject);
            });
        },
    }
}