<template>
  <section>
    <v-model.earnings.invoices v-slot="{model, query, fetch, clearQuery, pagination, search}">
      <div class="d-flex justify-content-between">
        <div>
          <v-button class="px-0 py-2" color="transparent" icon="arrow-left-s"
                    @click="$router.go(-1)"/>
        </div>
        <div class="hgroup flex-grow-1">
          <h1>Twoje faktury</h1>
          <h2>To Twoje centrum dowodzenia</h2>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <span class="card-title d-flex justify-content-between">
            Filtruj wyniki
            <v-button @click="clearQuery" :outline="true" color="secondary"  label="Wyczyść filtry" size="xs" icon="file-add-line-1"/>
          </span>

          <div class="d-flex align-items-end">
            <v-control-toggle v-model="query.is_paid" :color="['light','primary']"
                              :options="[null,true]" class="btn-xs me-1"
                              label="Opłacona"/>
            <v-control-toggle v-model="query.is_paid" :color="['light','primary']"
                              :options="[null,'null']" class="btn-xs mx-1"
                              label="Nieopłacona"/>

            <v-control-select class="mx-1 p-0" style="width: 180px" placeholder="Wszystkie" label="Wybierz okres" :options="model.months" v-model="query.month" :item="{label:'text', value:'value'}"/>
            <v-control-input :slim="true" class="mx-1 p-0" placeholder="Wszystkie" label="Numer faktury" v-model="query['invoice_number'].equal"></v-control-input>
            <v-control-date-range label="Wybierz zakres dat" v-model="query.created_at"></v-control-date-range>
          </div>
          <div class="d-flex justify-content-end">
            <v-button @click="fetch" color="primary px-3" size="sm" label="Szukaj" icon="search" />
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <span class="card-title">Twoje ostatnie faktury</span>
          <v-table v-slot="{item}" :items="model.invoices">
            <v-column label="Numer">
              <span class="font-weight-bold">{{ item.invoice_number ?? 'brak' }}</span>
            </v-column>
            <v-column label="Data wystawienia">
              <v-date :date="item.invoice" class="d-block" format="YYYY/MM/DD"/>
              <v-date :date="item.invoice" class="d-block" format="HH:mm"/>
            </v-column>
            <v-column label="Kwota" class="text-end">
              <span class="number">{{ item.amount }}</span>
              <span class="d-block unit">PLN</span>
            </v-column>
            <v-column label="Status">
              <div :class="{['bg-white']: !item.is_paid, ['text-danger']: !item.is_paid, ['bg-success']: item.is_paid, ['text-white']: item.is_paid}" class="lead-status"
                   style="width: 140px; margin: 0;">
                <span v-if="item.is_paid">OPŁACONA</span>
                <span v-else>NIEOPŁACONA</span>
              </div>
            </v-column>
            <v-column label="Pobierz" class="text-start" style="width: 300px">
              <div class="d-flex gap-2 justify-content-start align-items-center">
                <v-button color="secondary" icon="feedback"
                          label="Pobierz fakturę"
                          size="xs"/>
                <v-button color="secondary" icon="file-list"
                          label="Pobierz raport"
                          size="xs"/>
              </div>
            </v-column>
          </v-table>
        </div>
      </div>
    </v-model.earnings.invoices>
  </section>
</template>
<script>

import VButton from "@/components/Button.vue";
import VControlToggle from "@/components/Control/Toggle.vue";
import VColumn from "@/components/Column.vue";

export default {
  name: "route-agent.earnings.invoices",
  components: {VColumn, VControlToggle, VButton},
  view: "layer",
  route: {
    path: '/rozliczenia/faktury',
    title: 'Faktury'
  },
}
</script>